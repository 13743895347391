import { Component, OnInit, ViewChild } from '@angular/core';
import { NgForm, FormGroup } from '@angular/forms';
import { Globals } from 'src/app/globals/global';
import { SubirArchivoService } from 'src/app/services/subir-archivo.service';
import { ToastrService } from 'ngx-toastr';
import Swal from 'sweetalert2';
import { timeout } from 'rxjs/operators/timeout';

import * as FileSaver from 'file-saver';
import * as XLS from 'xlsx';
import { AuthService } from 'src/app/services/auth.service';
//import { userInfo } from 'os';
const EXCEL_TYPE = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';
const PDF_TYPE = 'application/pdf';
const EXCEL_EXTENSION = '.xls';
declare let $: any;

@Component({
  selector: 'app-upload-file',
  templateUrl: './upload-file.component.html',
  providers: [ Globals ],
  styleUrls: ['./upload-file.component.scss']
})
export class UploadFileComponent implements OnInit {
  public year = this.globals.year;

  // File input
  formImport: FormGroup;
  archivoSubir: File;
  files: File[] = [];
  resultado_busqueda: any={ENCONTRADOS:{datos:[]},NO_ENCONTRADOS:{datos:[]},ID_BUSQUEDA:-2};
  TITULOS_TABLA: any=[];
  filas: any;
  busquedaNom: string;
  busquedaDoc: string;
  
  public word: string;
  public xml: string;
  public pdf: string;
  public png: string;
  public jpg: string;
  public xlsx: string;


  // Propiedades
  public datos: any = {};
  @ViewChild('formEnviar') formEnviar: NgForm;
  @ViewChild ('inputFileValue') inputFileValue: any;
  public excel = [];

  constructor(public globals: Globals,
              private toastr: ToastrService,
              private subirArchivoService: SubirArchivoService,
               ) {
                this.word = 'application/vnd.openxmlformats-officedocument.wordprocessingml.document';
                this.xml = 'text/xml';
                this.pdf = 'application/pdf';
                this.png = 'image/png';
                this.jpg = 'image/jpeg';
                this.xlsx = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet';
               }

  ngOnInit() {
  }

  remove(i: any) {
    this.files.splice(i, 1);
    if (this.files.length <= 0) {
      this.RemoveAll();
    }
  }

  RemoveAll() {
    this.inputFileValue.nativeElement.value = '';
    this.archivoSubir = null;
    this.files = [];
  }


  descargarArchivo(Documento: String, Nombre:String)
  {

    this.datos=JSON.parse(localStorage.getItem('identityapp1'));
    this.datos.documento = Documento!=undefined?Documento:"";
    this.datos.nombre = Nombre!=undefined?Nombre:"";

    Swal.fire({
      allowOutsideClick: false,
      type: 'info',
      text: 'Espere por favor...',
      showConfirmButton: false,
    });
    Swal.showLoading();
    this.subirArchivoService.DescargarCertificado(this.datos)
    .subscribe((resp: any) => 
    {
        this.resultado_busqueda=JSON.parse(resp);
        this.TITULOS_TABLA=this.resultado_busqueda.ENCONTRADOS.datos.length>0?this.resultado_busqueda.ENCONTRADOS.datos[0]:this.resultado_busqueda.NO_ENCONTRADOS.datos[0];

        this.inputFileValue.nativeElement.value = '';
        this.archivoSubir = null;
        $('#modalUpFile').modal('hide');
        Swal.fire({
          position: 'center',
          type: 'success',
          title: '',
          showConfirmButton: true,
          timer: 1000
        });
        Swal.showLoading();
    }, (err: any) => {
      console.log('err de  subir archivo', err);
      this.inputFileValue.nativeElement.value = '';
      this.archivoSubir = undefined;
      this.toastr.error( '¡Los archivos no fueron cargados!', null, {
        timeOut: 1000
      });
      Swal.close();
    });
    return false;
  }

  buscarInvidual(Documento: String, Nombre:String)
  {

    this.datos=JSON.parse(localStorage.getItem('identityapp1'));
    this.datos.documento = Documento!=undefined?Documento:"";
    this.datos.nombre = Nombre!=undefined?Nombre:"";

    Swal.fire({
      allowOutsideClick: false,
      type: 'info',
      text: 'Espere por favor...',
      showConfirmButton: false,
    });
    Swal.showLoading();
    this.subirArchivoService.BusquedaIndividual(this.datos)
    .subscribe((resp: any) => 
    {
        this.resultado_busqueda=resp;
        this.TITULOS_TABLA=this.resultado_busqueda.ENCONTRADOS.datos.length>0?this.resultado_busqueda.ENCONTRADOS.datos[0]:this.resultado_busqueda.NO_ENCONTRADOS.datos[0];

        this.inputFileValue.nativeElement.value = '';
        this.archivoSubir = null;
        $('#modalUpFile').modal('hide');
        Swal.fire({
          position: 'center',
          type: 'success',
          title: '',
          showConfirmButton: true,
          timer: 1000
        });
        Swal.showLoading();
    }, (err: any) => {
      console.log('err de  subir archivo', err);
      this.inputFileValue.nativeElement.value = '';
      this.archivoSubir = undefined;
      this.toastr.error( '¡Los archivos no fueron cargados!', null, {
        timeOut: 1000
      });
      Swal.close();
    });
    return false;
  }

  descargarCertificado()
  {

    this.datos.formato="pdf";
    this.datos.id_reporte=this.resultado_busqueda.ID_BUSQUEDA;
    Swal.fire({
      allowOutsideClick: false,
      type: 'info',
      text: 'Espere por favor...',
      showConfirmButton: false,
    });
    Swal.showLoading();
    this.subirArchivoService.DescargarCertificado(this.datos)
    .subscribe((resp: any) => 
    {
      this.saveAsPdfFile(resp, "certificado.pdf");
        /*this.resultado_busqueda=JSON.parse(resp);
        this.TITULOS_TABLA=this.resultado_busqueda.ENCONTRADOS.datos.length>0?this.resultado_busqueda.ENCONTRADOS.datos[0]:this.resultado_busqueda.NO_ENCONTRADOS.datos[0];
*/
        this.inputFileValue.nativeElement.value = '';
        this.archivoSubir = null;
        $('#modalUpFile').modal('hide');
        Swal.fire({
          position: 'center',
          type: 'success',
          title: '',
          showConfirmButton: true,
          timer: 1000
        });
        Swal.showLoading();
    }, (err: any) => {
      console.log('err de  subir archivo', err);
      this.inputFileValue.nativeElement.value = '';
      this.archivoSubir = undefined;
      this.toastr.error( '¡Los archivos no fueron cargados!', null, {
        timeOut: 1000
      });
      Swal.close();
    });
    return false;
  }


  SeleccionArchivo(archivo: File, archivos: FileList) {

    if (!archivo || archivo.type !== this.xlsx) {
    this.toastr.error( 'Archivo no válido', null, {
      timeOut: 4000
    });
    this.inputFileValue.nativeElement.value = '';
    return;
  }

   this.archivoSubir = archivo;
  }
  
  SubirArchivo() {
    const name = this.archivoSubir.name;

    Swal.fire({
      allowOutsideClick: false,
      type: 'info',
      text: 'Espere por favor...',
      showConfirmButton: false
    });

     this.datos=JSON.parse(localStorage.getItem('identityapp1'));
    this.subirArchivoService.SubirArchivo(this.datos, this.archivoSubir, name).timeout(180000000)
    .subscribe((resp: any) => {
      //this.saveAsExcelFile(resp, name);
     // console.log(resp);
      this.resultado_busqueda=resp;
      this.TITULOS_TABLA=this.resultado_busqueda.ENCONTRADOS.datos.length>0?this.resultado_busqueda.ENCONTRADOS.datos[0]:this.resultado_busqueda.NO_ENCONTRADOS.datos[0];

      this.inputFileValue.nativeElement.value = '';
      this.archivoSubir = null;
      $('#modalUpFile').modal('hide');
      Swal.fire({
        position: 'center',
        type: 'success',
        title: '',
        showConfirmButton: true,
        timer: 1000
      });
      Swal.showLoading();

    }, (err: any) => {
      console.log('error al  subir archivo', err);
      this.inputFileValue.nativeElement.value = '';
      this.archivoSubir = undefined;
      this.toastr.error( '¡Los archivos no fueron cargados!', null, {
        timeOut: 4000
      });
      Swal.close();
    });
  }

  private saveAsPdfFile(buffer: any, fileName: string): void {
     const data: Blob = new Blob([buffer], {type: PDF_TYPE});
     FileSaver.saveAs(data, fileName+".pdf");
     setTimeout(() => {
       Swal.close();
       this.inputFileValue.nativeElement.value = '';
     }, 3000);
  }


}
