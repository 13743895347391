import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import 'rxjs/add/observable/throw';
import 'rxjs/add/operator/timeout';
import { Observable, empty } from 'rxjs';
import { catchError, timeout } from 'rxjs/operators';
@Injectable({
  providedIn: 'root'
})
export class SubirArchivoService  {
  
  
   Headers: any;
   identity: any;
   urlApi = 'https://sagrilaft.c0v.co/api/servicios-sagrilaft/servicios';
   //urlApi = 'http://localhost:8080/servicios-sagrilaft/servicios';

   constructor( private http: HttpClient) {
    
    this.Headers = new HttpHeaders();
    this.Headers.append('Content-Type', 'multipart/form-data');
    this.Headers.append('Accept', 'application/json');
    this.Headers = this.Headers.set('token', '8bf21b6f93822df8c5e5d1bd2f409deb');
    this.identity = JSON.parse(localStorage.getItem('identityapp1'));
  }

  DescargarCertificado(datos: any) {
  
    datos.id_pais = '1';
    datos.tipo_documento = 'C';
    datos.documento = this.identity.DOCUMENTO;
    //datos.documento = '52274343';
  
    const headers = new HttpHeaders();
    headers.append('Content-Type', 'multipart/form-data');
    headers.append('Accept', 'application/json');
    const options = { params: datos, headers };
    const formData: FormData = new FormData();
  
    // unico archivo:
//    return this.http.post(`${this.urlApi}/pr_exportar_cargar_token`, formData, {headers: options.headers,params: options.params, responseType: 'blob'});
    return (this.http.post(`${this.urlApi}/exportar_certificado`, formData, {headers: options.headers,params: options.params, responseType: 'blob'}));
  }

  SubirArchivo(datos?: any, data?: File, name?: string ) {
  
    datos.id_pais = '1';
    datos.tipo_documento = 'C';
    // datos.documento = this.identity.DOCUMENTO;
    //datos.documento = '52274343';
  
    const headers = this.Headers;
    const options = { params: datos, headers };
    const formData: FormData = new FormData();
  
    // unico archivo:
    const file = data;
    formData.append('file', file, file.name);
    //return (this.http.post(`/servicios/busqueda_masiva`, formData, {headers: options.headers,params: options.params, responseType: 'json'}).timeout(30000000));
    return (this.http.post(`${this.urlApi}/busqueda_masiva`, formData, {headers: options.headers,params: options.params, responseType: 'json'}));
   } 

   BusquedaIndividual(datos?: any) {
//    console.log('recibi los files', data);
  
    datos.id_pais = '1';
    datos.tipo_documento = 'C';
    // datos.documento = this.identity.DOCUMENTO;
    //datos.documento = '52274343';
  
    const headers = this.Headers;
    const options = { params: datos, headers };
    const formData: FormData = new FormData();
  
    // unico archivo:
    
    return this.http.post(`${this.urlApi}/busqueda_individual`, formData, {headers: options.headers,params: options.params, responseType: 'json'});
    //return this.http.post(`http://localhost:8080`, formData, {headers: options.headers,params: options.params, responseType: 'json'});
   }

}

