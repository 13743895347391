import { BrowserModule } from '@angular/platform-browser';
import { LOCALE_ID, NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule  } from '@angular/forms';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { APP_ROUTING } from './app.routes';
import { AppComponent } from './app.component';
import { LoginComponent } from './components/user/login/login.component';
import { UploadFileComponent } from './components/upload-file/upload-file.component';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { NavbarComponent } from './components/layout/navbar/navbar.component';
import { FooterComponent } from './components/layout/footer/footer.component';

// Plugins
import { ToastrModule } from 'ngx-toastr';
import { UiSwitchModule } from 'ngx-ui-switch';
import { TitleCasePipe } from '@angular/common';

/* Virtual Scroll */
import { ScrollingModule } from '@angular/cdk/scrolling';

import localeEsAr from '@angular/common/locales/es-Ar';
import { registerLocaleData } from '@angular/common';
registerLocaleData(localeEsAr, 'es-Ar');

import { MatFileUploadModule } from 'angular-material-fileupload';

import { MatProgressBarModule, MatButtonModule, MatMenuModule,
  MatToolbarModule,
  MatIconModule } from '@angular/material';
  
  import {  MatCardModule} from '@angular/material/card';
  //import { MatIconModule } from '@angular/material/icon';
import { InterceptorService } from './interceptors/interceptor.service';
import { MatTabsModule } from '@angular/material/tabs';


@NgModule({
  declarations: [
    AppComponent,
    LoginComponent,
    UploadFileComponent,
    NavbarComponent,
    FooterComponent,
  ],
  imports: [
    MatMenuModule,
    MatToolbarModule,
    MatIconModule,
    MatCardModule,
     MatButtonModule,
     MatTabsModule,
    MatProgressBarModule,
    MatIconModule,
    MatCardModule,
    BrowserModule,
    MatButtonModule,
    BrowserModule,
    MatFileUploadModule,
    ScrollingModule,
    CommonModule,
    APP_ROUTING,
    HttpClientModule,
    FormsModule,
    ReactiveFormsModule,
    BrowserAnimationsModule,
    ScrollingModule,
    UiSwitchModule.forRoot({
      size: 'small',
      color: 'rgb(10, 169, 209)',
      switchColor: '#FFFFFF',
      defaultBgColor: 'rgb(209, 10, 47)',
      defaultBoColor : '#dadada',
      checkedLabel: 'Si',
      uncheckedLabel: 'No'
    }),
    ToastrModule.forRoot({
      timeOut: 5000,
      positionClass: 'toast-bottom-right',
      preventDuplicates: true,
    }),
  ],
  providers: [{ provide: HTTP_INTERCEPTORS,
                useClass: InterceptorService,
                multi: true },
                TitleCasePipe,
               { provide: LOCALE_ID, useValue: 'es-Ar' }],
  bootstrap: [AppComponent]
})
export class AppModule { }
